import genel_baskan from './YonetimResimler/Genel_Baskan.jpeg'
import es_baskan from './YonetimResimler/Es_Baskan.jpeg'
import baskan2 from './YonetimResimler/2.Baskan.jpeg'
import baskan_vekili from './YonetimResimler/Baskan_Vekili.jpeg'
import baskan_yardimcisi_0 from './YonetimResimler/Baskan_Yardimcisi_00.jpeg'
import baskan_yardimcisi_1 from './YonetimResimler/Baskan_Yardimcisi_01.jpeg'
import baskan_yardimcisi_2 from './YonetimResimler/Baskan_Yardimcisi_02.jpeg'
import baskan_yardimcisi_3 from './YonetimResimler/Baskan_Yardimcisi_03.jpeg'
import baskan_yardimcisi_4 from './YonetimResimler/Baskan_Yardimcisi_04.jpeg'
import denetleme_kurulu from './YonetimResimler/Denetleme_Kurulu_Baskani.jpeg'
import disiplin_kurulu from './YonetimResimler/Disiplin_Kurulu_Baskani.jpeg'
import marmara_bolge_sorumlusu from './YonetimResimler/Marmara_Bolge_Sorumlusu.jpeg'
import guneydogu_bolge from './YonetimResimler/GD_Bolge_Temsilcisi.jpeg'
import genclik_kollari_baskani from './YonetimResimler/Genclik_Kollari_Baskani.jpeg'
import genclik_kollari_b_yardimcisi from './YonetimResimler/Genclik_K_Baskan_Yardimcisi.jpeg'
import genclik_kollari from './YonetimResimler/Genclik_Kollari.jpeg'
import baris_sen from './YonetimResimler/Baris_Sen.jpeg'
import baha_kurbanbekhov from './YonetimResimler/baha_kurmanbekhov.jpeg'
import genel_sekreter from './YonetimResimler/Genel_Sekreter.jpeg'
import kadin_kollari_baskani from './YonetimResimler/Kadin_Kollari_Baskani.jpeg'
import medya_basin_danismanı from './YonetimResimler/Medya_Basin_Danismani.jpeg'
import onursal_baskan from './YonetimResimler/Onursal_Baskan.jpeg'
import sayman from './YonetimResimler/Sayman.jpeg'
import sosyal_sorumluluk_projeleri from './YonetimResimler/Sosyal_Sorumluluk_Projeleri.jpeg'
import yoresel_yemekler_danismani_00 from './YonetimResimler/Yoresel_Yemekler_Danismani_00.jpeg'
import yoresel_yemekler_danismani_01 from './YonetimResimler/Yoresel_Yemekler_Danismani_01.jpeg'
import yonetim_kurulu_uyesi_00 from './YonetimResimler/Yonetim_Kurulu_Uyesi_00.jpeg'
import yonetim_kurulu_uyesi_01 from './YonetimResimler/Yonetim_Kurulu_Uyesi_01.jpeg'
import yonetim_kurulu_uyesi_02 from './YonetimResimler/Yonetim_Kurulu_Uyesi_02.jpeg'
import yonetim_kurulu_uyesi_03 from './YonetimResimler/Yonetim_Kurulu_Uyesi_03.jpeg'
import izmir_bolge_sorumlusu from './YonetimResimler/IzmirBolgeSorumlusu.jpeg'
import yasar_durbal from './YonetimResimler/yasar_durbal.jpeg'
import ahmet_altinkulac_yon_kur_uye from './YonetimResimler/ahmet_altinkulac_yon_kur_uye.jpeg'
import kerim_isik_yon_kur_uye from './YonetimResimler/Kerim_Isik_yon_kur_uye.jpeg'
import sercan_altinkulac_yon_kur_uye from './YonetimResimler/Sercan_Altinkulac_yon_kur_uye.jpeg'
import nihal_kilic_kadin_kol_bas_yar from './YonetimResimler/Nihal_Kilic_kadin_kol_bas_yar.jpeg'
export const Calisanlar = [
    {
        name: "Abdurrahman Matalman",
        title: "Genel Başkan",
        url: genel_baskan,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Kemal Kabadayı",
        title: "Eş Başkan",
        url: es_baskan,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Seyit Çetinkaya",
        title: "2. Başkan",
        url: baskan2,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Songül Altıner",
        title: "Başkan Vekili",
        url: baskan_vekili,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ercan Keleş",
        title: "Başkan Yardımcısı",
        url: baskan_yardimcisi_0,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Hüsamettin Öz",
        title: "Başkan Yardımcısı",
        url: baskan_yardimcisi_1,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Kaya Şerbet",
        title: "Başkan Yardımcısı",
        url: baskan_yardimcisi_2,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Özgür Doruköz",
        title: "Başkan Yardımcısı",
        url: baskan_yardimcisi_3,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Tolgahan Geliroğlu",
        title: "Başkan Yardımcısı",
        url: baskan_yardimcisi_4,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Hasan Adıgüzel",
        title: "Denetleme Kurulu Başkanı",
        url: denetleme_kurulu,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Sevgi Gökçe",
        title: "Disiplin Kurulu Başkanı",
        url: disiplin_kurulu,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ahmet Metin Yıldırım",
        title: "Marmara Bölge Sorumlusu",
        url: marmara_bolge_sorumlusu,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Tahir Gök",
        title: "İzmir Bölge Sorumlusu",
        url: izmir_bolge_sorumlusu,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },

    {
        name: "Murat Eryılmaz",
        title: "Güneydoğu Bölge Temsilcisi",
        url: guneydogu_bolge,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Baha Kurmanbekov",
        title: "Yurt Dışı Temsilcisi",
        url: baha_kurbanbekhov,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },

    {
        name: "Ahmet Doğan Yılmaz",
        title: "Gençlik Kolları Başkanı",
        url: genclik_kollari_baskani,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ennur Tan",
        title: "Gençlik Kolları Başkan Yardımcısı",
        url: genclik_kollari_b_yardimcisi,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Emrah Karabulut",
        title: "Gençlik Kolları Üyesi",
        url: genclik_kollari,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Barış Şen",
        title: "Gençlik Kolları Üyesi",
        url: baris_sen,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Kenan Usta",
        title: "Genel Sekreter",
        url: genel_sekreter,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ayten Ören",
        title: "Kadın Kolları Başkanı",
        url: kadin_kollari_baskani,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Nihal Kılıç",
        title: "Kadın Kolları Başkan Yardımcısı",
        url: nihal_kilic_kadin_kol_bas_yar,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ayten Ören",
        title: "Kadın Kolları Başkanı",
        url: kadin_kollari_baskani,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Şayeste Karabatak",
        title: "Medya Basın Danışmanı",
        url: medya_basin_danismanı,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ufuk Güngör",
        title: "Onursal Başkan",
        url: onursal_baskan,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Engin Dağ",
        title: "Sayman",
        url: sayman,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Akın Sarpdere",
        title: "Sosyal Sorumluluk Projeleri",
        url: sosyal_sorumluluk_projeleri,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ayten Saner",
        title: "Yöresel Yemekler Danışmanı",
        url: yoresel_yemekler_danismani_00,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Sinan Gelmez",
        title: "Yöresel Yemekler Danışmanı",
        url: yoresel_yemekler_danismani_01,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Sinan Dönmez",
        title: "Yönetim Kurulu Üyesi",
        url: yonetim_kurulu_uyesi_00,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Nihal Akın",
        title: "Yönetim Kurulu Üyesi",
        url: yonetim_kurulu_uyesi_01,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Salim Tırpan",
        title: "Yönetim Kurulu Üyesi",
        url: yonetim_kurulu_uyesi_02,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Yavuz Selim Şahin",
        title: "Yönetim Kurulu Üyesi",
        url: yonetim_kurulu_uyesi_03,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Yaşar Durbal",
        title: "Yönetim Kurulu Üyesi",
        url: yasar_durbal,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Ahmet Altınkulaç",
        title: "Yönetim Kurulu Üyesi",
        url: ahmet_altinkulac_yon_kur_uye,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Kerim Işık",
        title: "Yönetim Kurulu Üyesi",
        url: kerim_isik_yon_kur_uye,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },
    {
        name: "Sercan Altınkulaç",
        title: "Yönetim Kurulu Üyesi",
        url: sercan_altinkulac_yon_kur_uye,
        facebook_link: "",
        twitter_link: "",
        instagram_link: "",
    },

];