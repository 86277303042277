export const menuItemsData = [
    {
        title: 'ANA SAYFA',
        url: '/',

    },
    {
        title: 'KURUMSAL',
        url: '/kurumsal/hakkimizda',
        submenu: [
            {
                title: "Hakkımızda",
                url: "/kurumsal/hakkimizda",
            },
            {
                title: "Yönetim Kurulu",
                url: "/kurumsal/yonetim-kurulu",
            }
        ],
    },
    {
        title: 'ÇALIŞMA ORTAKLARI',
        url: '/calisma-ortaklari',
    },
    {
        title: 'PROJELERİMİZ',
        url: '/projelerimiz',
    },
    {
        title: 'GALERİ',
        url: '/galeri'
    },
    {
        title: 'İLETİŞİM',
        url: '/iletisim',
    },
];