import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AnaSayfa from './Pages/AnaSayfa/index';
import Hakkimizda from './Pages/Kurumsal/hakkimizda';
import YonetimKurulu from './Pages/Kurumsal/yonetim-kurulu';
import CalismaOrtaklari from './Pages/CalismaOrtaklari/index';
import Projelerimiz from './Pages/Projelerimiz/index';
import Galeri from './Pages/Galeri/index';
import Iletisim from './Pages/Iletisim/index';
import BlogSinglePage from './Pages/BlogSinglePage/BlogSinglePage';
import BlogSinglePage2 from './Pages/BlogSinglePage/BlogSinglePage2';
import NotFound from './Pages/NotFound';
import RegisterPage from './Pages/Register/RegisterPage';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AnaSayfa />} />
          <Route path="/kurumsal/hakkimizda" element={<Hakkimizda />} />
          <Route path="/kurumsal/yonetim-kurulu" element={<YonetimKurulu />} />
          <Route path="/calisma-ortaklari" element={<CalismaOrtaklari />} />
          <Route path="/projelerimiz" element={<Projelerimiz />} />
          <Route path="/projelerimiz/1" element={<BlogSinglePage />} />
          <Route path="/projelerimiz/2" element={<BlogSinglePage2 />} />
          <Route path="/galeri" element={<Galeri />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/uye-ol" element={<RegisterPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </div>
  );
}

export default App;
