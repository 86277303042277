import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const BlogSinglePage = () => {
    return (
        <div>
            <Header />
            <div className="container my-8 lg:my-16 mx-auto md:px-6">
                <article>
                    <div className="flex justify-start">
                        <div className="text-center md:max-w-xl lg:max-w-3xl">
                            <h2 className="mb-4 px-6 lg:px-0 text-3xl font-bold">TATVAN LEZZET GÜNLERİ &YÖRESEL YEMEK YARIŞMASI</h2>
                        </div>
                    </div>
                    <div className="py-8 lg:py-16 px-4 lg:px-0">
                        
                        <p className="text-2xl lg:text-3xl tracking-tight font-bold text-gray-900">
                            ORGANİZASYON KÜNYESİ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Planlanan Katılımcı Sayısı: 4500/6000 kişi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Katılımcılar: Ücretsiz olarak gerçekleşecek organizasyona herkes katılabilir.
                        </p>
                        <p className="text-2xl lg:text-3xl tracking-tight font-bold text-gray-900">
                            KATILIMCILAR
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Tatvan Anadolu Lisesi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Tatvan Gastronomi Öğrencileri
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Tatvan Halkı Yöresel Yemek Yarışması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Tatvan Mesleki ve Teknik Anadolu Lisesi
                        </p>
                        <p className="text-2xl lg:text-3xl tracking-tight font-bold text-gray-900">
                            ORGANİZASYON AKIŞI
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:00 Kortej ve Alana Yürüyüş
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:30 / 12:00 Açılış Seremonisi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Sunucu Didar Yanar
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Mutfak Kültürleri Araştırma Derneği Tatvan Temsilcisi Murat Eryılmaz’ın Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Başkanımız Sn. Abdurrahman Matalman’ın Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Türkiye Aşçılar Federasyonu (TAFED) Zeki Açıköz’ün Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Tatvan Belediye Başkanımız Sn. Mehmet Emin Geylani’nin Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Kültür ve Turizm Bakanımız Mehmet Nuri Ersoy’un Açılış Konuşması (Katılım ve takdirleri ile)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 12:00 / 12:30 Protokolün Fuar Alanı ve Sponsor Firma Stantları Ziyareti
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 12:30 / 13:30 MasterChef .................. ile Zeytinli ....... Yapımı Workshop’u
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 14:00 / 14:30 Panelistlerin "Tatvan Gastronomi Değerleri" Söyleşisi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 15:00 / 16:00 Yöresel Yemek Yarışması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 16:00 / 18:00 MasterChef .................. ile Zeytinli ....... Yapımı Workshop’u
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 18:00 / 18:30 Yarışma Sonucu Ödül Töreni
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 19:00 1. Gün Finali
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 20:00 Gelen Misafirlere Gala Yemeği
                        </p>
                    </div>
                </article>
            </div>
            <Footer />
        </div>
    )
}

export default BlogSinglePage;