export const Yazilar = [
    {
        id: "1",
        category: "Organizasyon",
        date: "2024-4-14",
        title: "1. TATVAN LEZZET GÜNLERİ VE YÖRESEL YEMEK YARIŞMASI",
        text: "",
        author: "",
        image: "",
    },
    {
        id: "2",
        category: "Organizasyon",
        date: "2024-6-10",
        title: "1. ESENYURT SOKAK LEZZETLERİ FESTİVALİ & YÖRESEL & FİNE DİNİNG YEMEK YARIŞMASI",
        text: "",
        author: "",
        image: "",
    },
];