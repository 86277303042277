import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Yazilar } from "../../assets/Yazilar";

const Projelerimiz = () => {
    return (
        <div>
            <Header />
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Projelerimiz</h2>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Mutfak Kültürleri Araştırma Derneği olarak yaptığımız etklinlikler ve projeler.</p>
                    </div>
                    <div className="grid gap-8 lg:grid-cols-2">
                        {Yazilar.map((yazi, index) => {
                            const bugun = new Date();
                            const belirliTarih = new Date(yazi.date);
                            const birGun = 1000 * 60 * 60 * 24;
                            let gunFarki = Math.floor((belirliTarih - bugun) / birGun); // Belirli tarih ile bugün arasındaki farkı negatif olarak alıyoruz
    
                            let outputFark;
                            if (gunFarki === 0) {
                                outputFark = `Bugün.`;
                            } else if (gunFarki > 0) {
                                if (gunFarki > 30) {
                                    const ayFarki = Math.floor(gunFarki / 30);
                                    if (ayFarki >= 12) {
                                        const yilFarki = Math.floor(ayFarki / 12);
                                        outputFark = `${yilFarki} yıl sonra.`;
                                    } else {
                                        outputFark = `${ayFarki} ay sonra.`;
                                    }
                                } else {
                                    outputFark = `${gunFarki} gün sonra.`;
                                }
                            } else {
                                gunFarki = -gunFarki; // Negatif değeri pozitife çeviriyoruz
                                if (gunFarki > 30) {
                                    const ayFarki = Math.floor(gunFarki / 30);
                                    if (ayFarki >= 12) {
                                        const yilFarki = Math.floor(ayFarki / 12);
                                        outputFark = `${yilFarki} yıl önce.`;
                                    } else {
                                        outputFark = `${ayFarki} ay önce.`;
                                    }
                                } else {
                                    outputFark = `${gunFarki} gün önce.`;
                                }
                            }
                            return (
                                <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700" key={index}>
                                    <div className="flex justify-between items-center mb-5 text-gray-500">
                                        <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                                            <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path></svg>
                                            {yazi.category}
                                        </span>
                                        <span className="text-sm">{outputFark}</span>
                                    </div>
                                    {/*<Link to={`/projelerimiz/${yazi.id}`}>
                                        <img className="mb-2 w-full" src={yazi.image} alt={yazi.title}></img>
                                    </Link>*/}
                                    <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><Link to={`/projelerimiz/${yazi.id}`}>{yazi.title}</Link></h2>
                                    <p className="mb-5 font-light text-gray-500 dark:text-gray-400">{yazi.text}</p>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center space-x-4">
                                            <span className="font-medium dark:text-white">
                                                {yazi.author}
                                            </span>
                                        </div>
                                        <Link to={`/projelerimiz/${yazi.id}`} className="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline">
                                            Daha fazla
                                            <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </article>
                            )
                        })}

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Projelerimiz;