export const hakkimizdaData = [
    {
        title: "Resim",
        url: "./tumEkip.jpeg",
    },
    {
        title: "Vizyon",
        text: "Turizm ve gıda sektörü başta olmak üzere, her alandaki yerli üretimi destekleyen Mutfak Kültürleri Araştırma Derneği, Türk mutfak ve yemek kültürlerini ulusal ve uluslararası platformlarda temsil etmektedir. Mutfak kültürlerimizin yerel ve uluslararası platformlarda en iyi şekilde tanıtılmasına öncelik tanıyan derneğimiz bu bağlamda Türk turizmine katkı sağlamaktadır. Mukader Derneği, yöresel gıda ürünlerimizin dünya mutfaklarında yer alması ve aşçılarımızın Türk yemekleriyle uluslararası platformlarda öne çıkması için önemli organizasyonlara imza atarak, misyonunu geliştirmeye devam etmektedir.",
    },
    {
        title: "Misyon",
        text: "Derneğimizin misyonu, mutfak ve yemek kültürlerini araştırmak, Türkiye’de mutfak sanatını geliştirmek, şeflerin mesleki eğitimini desteklemek, sosyal dayanışmanın teminini sağlamak; yurt içinde ve yurt dışında mutfak kültürlerimizin tanıtılmasını ve temsil edilmesine yardımcı olmak, gastronomi turizm başta olmak üzere bölgesel mutfak miraslarını desteklemek, ulusal ve yerel üreticilerle diyalogları geliştirmektir.",
    }
]