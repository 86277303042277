import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../Icons/Logo.png'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { signUp } from '../../services/index/users';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { userActions } from '../../store/reducers/userReducers';
import { useEffect } from 'react';

const RegisterPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const userState = useSelector(state => state.user)

    const {mutate, isLoading } = useMutation({
        mutationFn: ({ name, email, password }) => {
            return signUp({ name, email, password });
        },
        onSuccess: (data) => {
            dispatch(userActions.setUserInfo(data));
            localStorage.setItem('account', JSON.stringify(data));
        },
        onError: (error) => {
            toast.error(error.message);
            console.log(error);
        }
    });

    useEffect(() => {
        if(userState.userInfo) {
            navigate('/');
        }
    }, [navigate, userState.userInfo])
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
        },
        mode: "onChange"
    });
    const submitHandler = (data) => {
        const {name, email, password} = data;
        mutate({name, email, password})
    };

    const password = watch('password');
    return (
        <div className="bg-gray-50">
            <Header />
            <div className='container mx-auto md:py-10'>
                <section className="">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                        <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
                            Mukader
                        </Link>
                        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Hesabınızı Oluşturun
                                </h1>
                                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(submitHandler)}>
                                    <div>
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">İsim Soyisim</label>
                                        <input type="text" name="name" id="name" {...register("name", {
                                            minLength: {
                                                value: 1,
                                                message: "İsminiz en az 1 karakter olmalı!",
                                            },
                                            required: {
                                                value: true,
                                                message: "İsim boş bırakılamaz!",
                                            }
                                        })} className={`bg-gray-50 border border-gray-300 ${errors.name ? "border-red-500 focus:border-red-500 focus:outline-none" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white `} placeholder="İsim Soyisim" required="" />
                                        {errors.name?.message && (
                                            <p className='text-xs text-red-500 ml-3 mt-1 mb-0'>{errors.name.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type="email" name="email" id="email" {...register("email", {
                                            pattern: {
                                                // eslint-disable-next-lin
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "Geçerli bir mail adresi giriniz!",
                                            },
                                            required: {
                                                value: true,
                                                message: "Email boş bırakılamaz!",
                                            }
                                        })} className={`bg-gray-50 border border-gray-300 ${errors.email ? "border-red-500 focus:border-red-500 focus:outline-none" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} placeholder="name@company.com" required="" />
                                        {errors.email?.message && (
                                            <p className='text-xs text-red-500 ml-3 mt-1 mb-0'>{errors.email.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Şifre</label>
                                        <input type="password" name="password" id="password" {...register("password", {
                                            required: {
                                                value: true,
                                                message: "Şifre boş bırakılamaz!",
                                            },
                                            minLength: {
                                                value: 8,
                                                message: "Şifreniz en az 8 karakter olmalıdır!",
                                            },
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                                message: "Şifreniz en az 1 büyük harf, 1 küçük harf, ve bir sayı içermelidir!",
                                            }
                                        })} placeholder="••••••••" className={`bg-gray-50 border border-gray-300 ${errors.email ? "border-red-500 focus:border-red-500 focus:outline-none" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} required="" />
                                        {errors.password?.message && (
                                            <p className='text-xs text-red-500 ml-3 mt-1 mb-0'>{errors.password.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Şifreyi Doğrula</label>
                                        <input type="password" name="confirm-password" id="confirm-password" {...register("confirmPassword", {
                                            required: {
                                                value: true,
                                                message: "Şifreyi Doğrulama boş bırakılamaz!",
                                            },
                                            validate: (value) => {
                                                if (value !== password)
                                                    return "Şifreniz eşleşmedi!";
                                            }
                                        })} placeholder="••••••••" className={`bg-gray-50 border border-gray-300 ${errors.email ? "border-red-500 focus:border-red-500 focus:outline-none" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} required="" />
                                        {errors.confirmPassword?.message && (
                                            <p className='text-xs text-red-500 ml-3 mt-1 mb-0'>{errors.confirmPassword.message}</p>
                                        )}
                                    </div>
                                    <div className="flex items-start">
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">Kişisel verileriniz <Link className="font-medium text-red-600 hover:underline dark:text-primary-500" to="/">Aydınlatma Metni</Link> kapsamında işlenmektedir. Bu kapsamda <Link className="font-medium text-red-600 hover:underline dark:text-primary-500" to="/">Gizlilik Politikasını</Link> ve verilerimin işlenmesini kabul ettiğinizi onaylıyorsunuz.</label>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={!isValid || isLoading}
                                        className="w-full text-white bg-red-600 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 disabled:opacity-70 disabled:cursor-not-allowed">Kayıt Ol</button>
                                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                        Zaten hesabınız var mı? <Link to="/login" className="font-medium text-red-600 hover:underline dark:text-red-500">Giriş Yap</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default RegisterPage;