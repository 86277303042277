export const IletisimData = [
    {
        title: "Adres",
        text: "Selahattin Pınar Caddesi Kazım Erbil İşhanı No:19 Kat:4 No:303 Mecidiyeköy/Şişli/İstanbul",
        which_icon: "1",
    },
    {
        title: "Telefon Numarası",
        text: "+90 537 838 65 87",
        which_icon: "2",
    },
    {
        title: "Mail",
        text: "mukader.tr@gmail.com",
        which_icon: "3",
    },
];