import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { galeriData } from "../../assets/galeriData";

const Galeri = () => {
    return (
        <div>
            <Header />
            {galeriData.map((foto, index) => {
                return (
                    <div className="inline-block w-96 h-96 gap-2 mx-10 my-24" key={index}>
                        <img src={foto.url} alt={foto.alt}></img>
                    </div>
                )
            })}
            <Footer />
        </div>
    )
}

export default Galeri;