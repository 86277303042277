import rational from './OrtaklarData/Rational-AG.svg';
import abooov from './OrtaklarData/abooov.jpeg'
import bonna from './OrtaklarData/bonna.jpeg'
import oztiryakiler from './OrtaklarData/oztiryakiler.jpeg'

export const OrtakData = [
    {
        title: "Rational Mutfak",
        image: rational,
    },
    {
        title: "Abooov Kebap",
        image: abooov,
    },
    {
        title: "Bonna",
        image: bonna
    },
    {
        title: "Öztiryakiler",
        image: oztiryakiler,
    },
];