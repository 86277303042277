import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

const BlogSinglePage2 = () => {

    return (
        <div>
            <Header />
            <div className="container my-8 lg:my-16 mx-auto md:px-6">
                <article>
                    <div className="flex justify-start">
                        <div className="text-center md:max-w-xl lg:max-w-3xl">
                            <h2 className="mb-4 px-6 lg:px-0 text-3xl font-bold">1. ESENYURT SOKAK LEZZETLERİ FESTİVALİ & YÖRESEL & FİNE DİNİNG YEMEK YARIŞMASI</h2>
                        </div>
                    </div>
                    <div className="py-8 lg:py-16 px-4 lg:px-0">
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            ORGANİZASYON KÜNYESİ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Planlanan Katılımcı Sayısı: 300.000/500.000 kişi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Tarih: 14 MART 2024 PERŞEMBE ve 10 MART 2024 PAZAR ARASI BELİRLENECEK PERŞEMBE, CUMA, CUMARTESİ, PAZAR 4 GÜN
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Saat: 10:00 / 22:00 Arası ESENYURT / İSTANBUL
                        </p>
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            KATILIMCILAR
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * ESENYURT BELEDİYESİ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * TAFED TÜRKİYE AŞÇILAR FEDERASYONU
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * MUKADER MUTFAK KÜLTÜRLERİ ARAŞTIRMA DERNEĞİ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * GURME MAGAZİN
                        </p>
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            ORGANİZASYON AKIŞI 1.Gün Perşembe
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:00 AÇILIŞ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 12:30 KORTEJ VE ALANA YÜRÜYÜŞ
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 13:00 Açılış Seremonisi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * Sunucu; Ufuk GÜNGÖR açılış konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            *  Mutfak Kültürleri Araştırma Derneği Başkanı Başkanımız Sn Abdurrahman Matalman Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            *  Türkiye Aşçılar Federasyonu Başkanımız Sayın Zeki Açıköz Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            *  Esenyurt Belediye Başkanımız Sn. Kemal Deniz Bozkurt Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            *  Esenyurt Kaymakamı Sn. Fatih Çobanoğlu Açılış Konuşması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            * İstanbul Büyükşehir Belediye Başkanı Sn. Ekrem İmamoğlu
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl ml-4">
                            *  TBMM Milletvekilleri …
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 16:00 Protokol ile Stand gezisi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 18:00 Sahnede Workshop ( Konusu ve misafir chef sonra belirlenecek)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 19:00 Konser Festival Alanı (Belediye insiyatifi)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Daha detaylı program tarihe, katılıma göre değişiklik olabilir.
                        </p>
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            ORGANİZASYON AKIŞI 2.Gün Cuma
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:00 Liseler Bireysel Yemek Yarışması 1. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 11:30 Liseler Bireysel Yemek Yarışması 2. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 13:00 Liseler Bireysel Yemek Yarışması 3. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 15:00 Esenyurt’lu Kadınlar arası Yöresel Yemek yarışması
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 16:00 Tanınmış bir chef Workshop
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 17:00 Liseler Bireysel Yemek Yarışması Yarışma Sonucu Ödül Töreni
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 19:00 Konser Festival Alanı (Belediye insiyatifi)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Daha detaylı program tarihe, katılıma göre değişiklik olabilir.
                        </p>
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            ORGANİZASYON AKIŞI 3.Gün Cumartesi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:00 Üniversite Bireysel Yemek Yarışması 1. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 11:30 Üniversite Bireysel Yemek Yarışması 2. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 13:00 Üniversite Bireysel Yemek Yarışması 3. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 15:00 Yöresel Yemek workshop
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 16:00 Tanınmış bir chef Workshop
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 17:00 Üniversite Bireysel Yemek Yarışması Yarışma Sonucu Ödül Töreni
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 19:00 Esenyurt Belediye Başkanı Sn. Kemal Deniz Bozkurt ile sahnede Yemek yapılacak
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 20:00 Konser Festival Alanı (Belediye insiyatifi)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Daha detaylı program tarihe, katılıma göre değişiklik olabilir.
                        </p>
                        <p className="text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            ORGANİZASYON AKIŞI 4.Gün Pazar
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 10:00 Liseler Takım Yemek Yarışması 1. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 11:30 Liseler Takım Yemek Yarışması 2. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 13:00 Üniversite Takım Yemek Yarışması 1. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 15:00 Üniversite Takım Yemek Yarışması 2. grup
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 16:00 Tanınmış bir chef Workshop
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 17:00 Lise ve Üniversite Takım Yemek Yarışması Yarışma Sonucu Ödül Töreni
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 19:00 Gala Yemeği Katılan misafirlere plaket takdimi
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * 20:00 Konser Festival Alanı (Belediye insiyatifi)
                        </p>
                        <p className="font-light text-gray-500 sm:text-xl">
                            * Daha detaylı program tarihe, katılıma göre değişiklik olabilir.
                        </p>
                    </div>
                </article>
            </div>
            <Footer />
        </div>
    )
}

export default BlogSinglePage2;