import foto00 from './Galeri/00.jpeg'
import foto01 from './Galeri/01.jpeg'
import foto02 from './Galeri/02.jpeg'
import foto03 from './Galeri/03.jpeg'
import foto04 from './Galeri/04.jpeg'
import foto05 from './Galeri/05.jpeg'
import foto06 from './Galeri/06.jpeg'
import foto07 from './Galeri/07.jpeg'
import foto08 from './Galeri/08.jpeg'
import foto09 from './Galeri/09.jpeg'
import foto10 from './Galeri/10.jpeg'
import foto11 from './Galeri/11.jpeg'
import foto12 from './Galeri/12.jpeg'
import foto13 from './Galeri/13.jpeg'
import foto14 from './Galeri/14.jpeg'
import foto15 from './Galeri/15.jpeg'
import foto16 from './Galeri/16.jpeg'
import foto17 from './Galeri/17.jpeg'
import foto18 from './Galeri/18.jpeg'
import foto19 from './Galeri/19.jpeg'
import foto20 from './Galeri/20.jpeg'
import foto21 from './Galeri/21.jpeg'
import foto22 from './Galeri/22.jpeg'
import foto23 from './Galeri/23.jpeg'
import foto24 from './Galeri/24.jpeg'
import foto25 from './Galeri/25.jpeg'
import foto26 from './Galeri/26.jpeg'
import foto27 from './Galeri/27.jpeg'
import foto28 from './Galeri/28.jpeg'
import foto29 from './Galeri/29.jpeg'
import foto30 from './Galeri/30.jpeg'
import foto31 from './Galeri/31.jpeg'
import foto32 from './Galeri/32.jpeg'
import foto33 from './Galeri/33.jpeg'
import foto34 from './Galeri/34.jpeg'
import foto35 from './Galeri/35.jpeg'
import foto36 from './Galeri/36.jpeg'
import foto37 from './Galeri/37.jpeg'
import foto38 from './Galeri/38.jpeg'
import foto39 from './Galeri/39.jpeg'
import foto40 from './Galeri/40.jpeg'
import foto41 from './Galeri/41.jpeg'
import foto42 from './Galeri/42.jpeg'
import foto43 from './Galeri/43.jpeg'
import foto44 from './Galeri/44.jpeg'
import foto45 from './Galeri/45.jpeg'
import foto46 from './Galeri/46.jpeg'
import foto47 from './Galeri/47.jpeg'
import foto48 from './Galeri/48.jpeg'
import foto49 from './Galeri/49.jpeg'
import foto50 from './Galeri/50.jpeg'
import foto51 from './Galeri/51.jpeg'
import foto52 from './Galeri/52.jpeg'
import foto53 from './Galeri/53.jpeg'
import foto54 from './Galeri/54.jpeg'
import foto55 from './Galeri/55.jpeg'
import foto56 from './Galeri/56.jpeg'
import foto57 from './Galeri/57.jpeg'
import foto58 from './Galeri/58.jpeg'
import foto59 from './Galeri/59.jpeg'
import foto60 from './Galeri/60.jpeg'
import foto61 from './Galeri/61.jpeg'
import foto62 from './Galeri/62.jpeg'
import foto63 from './Galeri/63.jpeg'
import foto64 from './Galeri/64.jpeg'
import foto65 from './Galeri/65.jpeg'
import foto66 from './Galeri/66.jpeg'
import foto67 from './Galeri/67.jpeg'
import foto68 from './Galeri/68.jpeg'
import foto69 from './Galeri/69.jpeg'
import foto70 from './Galeri/70.jpeg'
import foto71 from './Galeri/71.jpeg'
import foto72 from './Galeri/72.jpeg'
import foto73 from './Galeri/73.jpeg'
import foto74 from './Galeri/74.jpeg'
import foto75 from './Galeri/75.jpeg'
import foto76 from './Galeri/76.jpeg'
import foto77 from './Galeri/77.jpeg'
import foto78 from './Galeri/78.jpeg'
import foto79 from './Galeri/79.jpeg'
import foto80 from './Galeri/80.jpeg'
import foto81 from './Galeri/81.jpeg'
import foto82 from './Galeri/82.jpeg'
import foto83 from './Galeri/83.jpeg'
import foto84 from './Galeri/84.jpeg'
import foto85 from './Galeri/85.jpeg'
import foto86 from './Galeri/86.jpeg'
import foto87 from './Galeri/87.jpeg'
import foto88 from './Galeri/88.jpeg'
import foto89 from './Galeri/89.jpeg'
import foto90 from './Galeri/90.jpeg'
import foto91 from './Galeri/91.jpeg'
import foto92 from './Galeri/92.jpeg'
import foto93 from './Galeri/93.jpeg'
import foto94 from './Galeri/94.jpeg'
import foto95 from './Galeri/95.jpeg'
import foto96 from './Galeri/96.jpeg'
import foto97 from './Galeri/97.jpeg'
import foto98 from './Galeri/98.jpeg'
import foto99 from './Galeri/99.jpeg'
import foto100 from './Galeri/100.jpeg'
import foto101 from './Galeri/101.jpeg'
import foto102 from './Galeri/102.jpeg'


export const galeriData = [
    {
        id: "1",
        alt: "00",
        url: foto00,
    },
    {
        id: "1",
        alt: "00",
        url: foto00,
    },
    {
        id: "2",
        alt: "01",
        url: foto01,
    },
    {
        id: "3",
        alt: "02",
        url: foto02,
    },
    {
        id: "4",
        alt: "03",
        url: foto03,
    },
    {
        id: "5",
        alt: "04",
        url: foto04,
    },
    {
        id: "6",
        alt: "05",
        url: foto05,
    },
    {
        id: "7",
        alt: "06",
        url: foto06,
    },
    {
        id: "8",
        alt: "07",
        url: foto07,
    },
    {
        id: "9",
        alt: "08",
        url: foto08,
    },
    {
        id: "10",
        alt: "09",
        url: foto09,
    },
    {
        id: "11",
        alt: "10",
        url: foto10,
    },
    {
        id: "12",
        alt: "11",
        url: foto11,
    },
    {
        id: "13",
        alt: "12",
        url: foto12,
    },
    {
        id: "14",
        alt: "13",
        url: foto13,
    },
    {
        id: "15",
        alt: "14",
        url: foto14,
    },

    {
        id: "16",
        alt: "15",
        url: foto15,
    },
    {
        id: "17",
        alt: "16",
        url: foto16,
    },
    {
        id: "18",
        alt: "17",
        url: foto17,
    },
    {
        id: "19",
        alt: "18",
        url: foto18,
    },
    {
        id: "20",
        alt: "19",
        url: foto19,
    },
    {
        id: "21",
        alt: "20",
        url: foto20,
    },
    {
        id: "22",
        alt: "21",
        url: foto21,
    },
    {
        id: "23",
        alt: "22",
        url: foto22,
    },
    {
        id: "24",
        alt: "23",
        url: foto23,
    },
    {
        id: "25",
        alt: "24",
        url: foto24,
    },
    {
        id: "26",
        alt: "25",
        url: foto25,
    },
    {
        id: "27",
        alt: "26",
        url: foto26,
    },
    {
        id: "28",
        alt: "27",
        url: foto27,
    },
    {
        id: "29",
        alt: "28",
        url: foto28,
    },
    {
        id: "30",
        alt: "29",
        url: foto29,
    },
    {
        id: "31",
        alt: "30",
        url: foto30,
    },
    {
        id: "32",
        alt: "31",
        url: foto31,
    },
    {
        id: "33",
        alt: "32",
        url: foto32,
    },
    {
        id: "34",
        alt: "33",
        url: foto33,
    },
    {
        id: "35",
        alt: "34",
        url: foto34,
    },
    {
        id: "36",
        alt: "35",
        url: foto35,
    },
    {
        id: "37",
        alt: "36",
        url: foto36,
    },
    {
        id: "38",
        alt: "37",
        url: foto37,
    },
    {
        id: "39",
        alt: "38",
        url: foto38,
    },
    {
        id: "40",
        alt: "39",
        url: foto39,
    },
    {
        id: "41",
        alt: "40",
        url: foto40,
    },
    {
        id: "42",
        alt: "41",
        url: foto41,
    },
    {
        id: "43",
        alt: "42",
        url: foto42,
    },
    {
        id: "44",
        alt: "43",
        url: foto43,
    },
    {
        id: "45",
        alt: "44",
        url: foto44,
    },
    {
        id: "46",
        alt: "45",
        url: foto45,
    },
    {
        id: "47",
        alt: "46",
        url: foto46,
    },
    {
        id: "48",
        alt: "47",
        url: foto47,
    },
    {
        id: "49",
        alt: "48",
        url: foto48,
    },
    {
        id: "50",
        alt: "49",
        url: foto49,
    },
    {
        id: "51",
        alt: "50",
        url: foto50,
    },
    {
        id: "52",
        alt: "51",
        url: foto51,
    },
    {
        id: "53",
        alt: "52",
        url: foto52,
    },
    {
        id: "54",
        alt: "53",
        url: foto53,
    },
    {
        id: "55",
        alt: "54",
        url: foto54,
    },
    {
        id: "56",
        alt: "55",
        url: foto55,
    },
    {
        id: "57",
        alt: "56",
        url: foto56,
    },
    {
        id: "58",
        alt: "57",
        url: foto57,
    },
    {
        id: "59",
        alt: "58",
        url: foto58,
    },
    {
        id: "60",
        alt: "59",
        url: foto59,
    },
    {
        id: "61",
        alt: "60",
        url: foto60,
    },
    {
        id: "62",
        alt: "61",
        url: foto61,
    },
    {
        id: "63",
        alt: "62",
        url: foto62,
    },
    {
        id: "64",
        alt: "63",
        url: foto63,
    },
    {
        id: "65",
        alt: "64",
        url: foto64,
    },
    {
        id: "66",
        alt: "65",
        url: foto65,
    },
    {
        id: "67",
        alt: "66",
        url: foto66,
    },
    {
        id: "68",
        alt: "67",
        url: foto67,
    },
    {
        id: "69",
        alt: "68",
        url: foto68,
    },
    {
        id: "70",
        alt: "69",
        url: foto69,
    },
    {
        id: "71",
        alt: "70",
        url: foto70,
    },
    {
        id: "72",
        alt: "71",
        url: foto71,
    },
    {
        id: "73",
        alt: "72",
        url: foto72,
    },
    {
        id: "74",
        alt: "73",
        url: foto73,
    },
    {
        id: "75",
        alt: "74",
        url: foto74,
    },
    {
        id: "76",
        alt: "75",
        url: foto75,
    },
    {
        id: "77",
        alt: "76",
        url: foto76,
    },
    {
        id: "78",
        alt: "77",
        url: foto77,
    },
    {
        id: "79",
        alt: "78",
        url: foto78,
    },
    {
        id: "80",
        alt: "79",
        url: foto79,
    },
    {
        id: "81",
        alt: "80",
        url: foto80,
    },
    {
        id: "82",
        alt: "81",
        url: foto81,
    },
    {
        id: "83",
        alt: "82",
        url: foto82,
    },
    {
        id: "84",
        alt: "83",
        url: foto83,
    },
    {
        id: "85",
        alt: "84",
        url: foto84,
    },
    {
        id: "86",
        alt: "85",
        url: foto85,
    },
    {
        id: "87",
        alt: "86",
        url: foto86,
    },
    {
        id: "88",
        alt: "87",
        url: foto87,
    },
    {
        id: "89",
        alt: "88",
        url: foto88,
    },
    {
        id: "90",
        alt: "89",
        url: foto89,
    },
    {
        id: "91",
        alt: "90",
        url: foto90,
    },
    {
        id: "92",
        alt: "91",
        url: foto91,
    },
    {
        id: "93",
        alt: "92",
        url: foto92,
    },
    {
        id: "94",
        alt: "93",
        url: foto93,
    },
    {
        id: "95",
        alt: "94",
        url: foto94,
    },
    {
        id: "96",
        alt: "95",
        url: foto95,
    },
    {
        id: "97",
        alt: "96",
        url: foto96,
    },
    {
        id: "98",
        alt: "97",
        url: foto97,
    },
    {
        id: "99",
        alt: "98",
        url: foto98,
    },
    {
        id: "100",
        alt: "99",
        url: foto99,
    },
    {
        id: "101",
        alt: "100",
        url: foto100,
    },
    {
        id: "102",
        alt: "101",
        url: foto101,
    },
    {
        id: "103",
        alt: "102",
        url: foto102,
    },
];