import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { Calisanlar } from "../../assets/Calisanlar";
import { FaFacebookF } from "react-icons/fa6";
import { RiTwitterXLine } from "react-icons/ri";

const Yonetim_Kurulu = () => {
    return (
        <div>
            <Header />
            <section className="bg-white dark:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                    <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Yönetim Kurulu</h2>
                        <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400"></p>
                    </div>
                    <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {Calisanlar.map((calisan, index) => {
                            return (
                                <div className="text-center text-gray-500 dark:text-gray-400" key={index}>
                                    <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={calisan.url} alt={calisan.name} />
                                    <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        <p>{calisan.name}</p>
                                    </h3>
                                    <p>{calisan.title}</p>
                                    <ul className="flex justify-center mt-4 space-x-4">
                                        <li>
                                            <Link to="#" className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                                                <FaFacebookF className="w-6 h-6" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="text-black hover:text-gray-600 dark:hover:text-white">
                                                <RiTwitterXLine className="w-6 h-6" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Yonetim_Kurulu;