import baskan from './SliderData/MutfakKulturleriArastirmaDernegiBaskani.png'
import genel_baskan from './YonetimResimler/Genel_Baskan.jpeg'
import es_baskan from './YonetimResimler/Es_Baskan.jpeg'
import baskan2 from './YonetimResimler/2.Baskan.jpeg'
import baskan_vekili from './YonetimResimler/Baskan_Vekili.jpeg'
import baskan_yardimcisi_0 from './YonetimResimler/Baskan_Yardimcisi_00.jpeg'
import baskan_yardimcisi_1 from './YonetimResimler/Baskan_Yardimcisi_01.jpeg'
import baskan_yardimcisi_2 from './YonetimResimler/Baskan_Yardimcisi_02.jpeg'
import baskan_yardimcisi_3 from './YonetimResimler/Baskan_Yardimcisi_03.jpeg'
import baskan_yardimcisi_4 from './YonetimResimler/Baskan_Yardimcisi_04.jpeg'
import denetleme_kurulu from './YonetimResimler/Denetleme_Kurulu_Baskani.jpeg'
import disiplin_kurulu from './YonetimResimler/Disiplin_Kurulu_Baskani.jpeg'
import marmara_bolge_sorumlusu from './YonetimResimler/Marmara_Bolge_Sorumlusu.jpeg'
import guneydogu_bolge from './YonetimResimler/GD_Bolge_Temsilcisi.jpeg'
import genclik_kollari_baskani from './YonetimResimler/Genclik_Kollari_Baskani.jpeg'
import genclik_kollari_b_yardimcisi from './YonetimResimler/Genclik_K_Baskan_Yardimcisi.jpeg'
import genclik_kollari from './YonetimResimler/Genclik_Kollari.jpeg'
import baris_sen from './YonetimResimler/Baris_Sen.jpeg'
import baha_kurbanbekhov from './YonetimResimler/baha_kurmanbekhov.jpeg'
import genel_sekreter from './YonetimResimler/Genel_Sekreter.jpeg'
import kadin_kollari_baskani from './YonetimResimler/Kadin_Kollari_Baskani.jpeg'
import medya_basin_danismanı from './YonetimResimler/Medya_Basin_Danismani.jpeg'
import onursal_baskan from './YonetimResimler/Onursal_Baskan.jpeg'
import sayman from './YonetimResimler/Sayman.jpeg'
import sosyal_sorumluluk_projeleri from './YonetimResimler/Sosyal_Sorumluluk_Projeleri.jpeg'
import yoresel_yemekler_danismani_00 from './YonetimResimler/Yoresel_Yemekler_Danismani_00.jpeg'
import yoresel_yemekler_danismani_01 from './YonetimResimler/Yoresel_Yemekler_Danismani_01.jpeg'
import yonetim_kurulu_uyesi_00 from './YonetimResimler/Yonetim_Kurulu_Uyesi_00.jpeg'
import yonetim_kurulu_uyesi_01 from './YonetimResimler/Yonetim_Kurulu_Uyesi_01.jpeg'
import yonetim_kurulu_uyesi_02 from './YonetimResimler/Yonetim_Kurulu_Uyesi_02.jpeg'
import yonetim_kurulu_uyesi_03 from './YonetimResimler/Yonetim_Kurulu_Uyesi_03.jpeg'
import izmir_bolge_sorumlusu from './YonetimResimler/tahir_gok_y_k_u.jpeg'
import yasar_durbal from './YonetimResimler/yasar_durbal.jpeg'
import ahmet_altinkulac_yon_kur_uye from './YonetimResimler/ahmet_altinkulac_yon_kur_uye.jpeg'
import kerim_isik_yon_kur_uye from './YonetimResimler/Kerim_Isik_yon_kur_uye.jpeg'
import sercan_altinkulac_yon_kur_uye from './YonetimResimler/Sercan_Altinkulac_yon_kur_uye.jpeg'
import nihal_kilic_kadin_kol_bas_yar from './YonetimResimler/Nihal_Kilic_kadin_kol_bas_yar.jpeg'
//import tanisma_yemegi from './SliderData/tanisma_yemegi.png'
import foto79 from './SliderData/79.png'
import etkinlik01 from './SliderData/Etkinlik-01.png';
import altinKelebekVeVefaOdulleri from './SliderData/Altın Kalp ve Vefa Ödülleri.png';
import kadinlar_gunu from './SliderData/8_Mart_Duyuru.png';

export const SliderData = [
    {
        type: "image",
        image: kadinlar_gunu,
        url: '/',
    },
    {
        type: "image",
        image: etkinlik01,
        url: '/',
    },
    {
        type: "image",
        image: altinKelebekVeVefaOdulleri,
        url: '/',
    },
    {
        type: "image",
        image: foto79,
        url: "/",
    },
    {
        type: 'image',
        image: baskan,
        url: "/",
    },
    {
        type: 'image',
        image: genel_baskan,
        url: "/",
    },
    {
        type: 'image',
        image: es_baskan,
        url: "/",
    },
    {
        type: 'image',
        image: baskan2,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_vekili,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_yardimcisi_0,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_yardimcisi_1,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_yardimcisi_2,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_yardimcisi_3,
        url: "/",
    },
    {
        type: 'image',
        image: baskan_yardimcisi_4,
        url: "/",
    },
    {
        type: 'image',
        image: denetleme_kurulu,
        url: "/",
    },
    {
        type: 'image',
        image: disiplin_kurulu,
        url: "/",
    },
    {
        type: 'image',
        image: marmara_bolge_sorumlusu,
        url: "/",
    },
    {
        type: 'image',
        image: guneydogu_bolge,
        url: "/",
    },
    {
        type: 'image',
        image: genclik_kollari_baskani,
        url: "/",
    },
    {
        type: 'image',
        image: genclik_kollari_b_yardimcisi,
        url: "/",
    },
    {
        type: 'image',
        image: genclik_kollari,
        url: "/",
    },
    {
        type: 'image',
        image: baris_sen,
        url: "/",
    },
    {
        type: 'image',
        image: baha_kurbanbekhov,
        url: "/",
    },
    {
        type: 'image',
        image: genel_sekreter,
        url: "/",
    },
    {
        type: 'image',
        image: kadin_kollari_baskani,
        url: "/",
    },
    {
        type: 'image',
        image: medya_basin_danismanı,
        url: "/",
    },
    {
        type: 'image',
        image: onursal_baskan,
        url: "/",
    },
    {
        type: 'image',
        image: sayman,
        url: "/",
    },
    {
        type: 'image',
        image: sosyal_sorumluluk_projeleri,
        url: "/",
    },
    {
        type: 'image',
        image: yoresel_yemekler_danismani_00,
        url: "/",
    },
    {
        type: 'image',
        image: yoresel_yemekler_danismani_01,
        url: "/",
    },
    {
        type: 'image',
        image: yonetim_kurulu_uyesi_00,
        url: "/",
    },
    {
        type: 'image',
        image: yonetim_kurulu_uyesi_01,
        url: "/",
    },
    {
        type: 'image',
        image: yonetim_kurulu_uyesi_02,
        url: "/",
    },
    {
        type: 'image',
        image: yonetim_kurulu_uyesi_03,
        url: "/",
    },
    {
        type: 'image',
        image: izmir_bolge_sorumlusu,
        url: "/",
    },
    {
        type: 'image',
        image: yasar_durbal,
        url: "/",
    },
    {
        type: 'image',
        image: ahmet_altinkulac_yon_kur_uye,
        url: "/",
    },
    {
        type: 'image',
        image: kerim_isik_yon_kur_uye,
        url: "/",
    },
    {
        type: 'image',
        image: sercan_altinkulac_yon_kur_uye,
        url: "/",
    },
    {
        type: 'image',
        image: nihal_kilic_kadin_kol_bas_yar,
        url: "/",
    },
];