export const IletisimSosyalMedyaData = [
    {
        text: "@mukader_2023",
        which_icon: "1",
        link: "https://instagram.com/mukader_2023",
    },
    {
        text: "@mukader",
        which_icon: "2",
        link: "https://facebook.com/groups/1451284842369119/",
    },
];